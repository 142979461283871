import React from "react"
import * as styles from "./styles"
import classnames from "classnames"
import PropTypes from "prop-types"
import { H1 } from "../../ui/headers/Headers"

import Fact from "./fact/Fact"
import usePostsContext from "../../../hooks/usePostsContext"
import useQuery from "./useQuery"
import scrollTo from "../../../utils/scrollTo"

const InfoGrid = ({
    props: {
        subheader,
        header,
        tile1,
        tile2,
        tile3,
        tile4,
        tile5,
        tile6,
        tile7,
        tile8,
        tile9,
        tile10,
    },
}) => {
    const { insights, events } = usePostsContext()
    const insightsLength = insights.all.reduce((a, b) => a + b.acf.length, 0)
    const eventsWithRecordings = events.all.filter(event => event.acf.recordings === true);
    const eventsAttended = 50
    const { speakersNumber, conferencesNumber } = useQuery()
    return (
        <section className={styles.InfoGrid}>
            <div className="l-Inner row">
                <div
                    className={classnames("small-12", styles.InfoGrid__Header)}
                >
                    <h4 dangerouslySetInnerHTML={{ __html: subheader }} />
                    <H1 content={header} />
                </div>
                <div className="small-12">
                    <div className={styles.InfoGrid__FactsContainer}>
                        <Fact
                            bigHeader
                            header={insights.all.length + eventsWithRecordings.length}
                            text={tile1.text}
                            backgroundType="dark"
                        />
                        <Fact
                            link={tile2.link}
                            text={tile2.header}
                            logo={tile2.logo}
                        />
                        <Fact
                            link={tile3.link}
                            text={tile3.header}
                            logo={tile3.logo}
                        />
                        <Fact
                            link={tile4.link}
                            text={tile4.header}
                            logo={tile4.logo}
                        />
                        <Fact
                            backgroundType="image"
                            backgroundImage={tile5.image}
                        />
                        <Fact
                            backgroundType="dark"
                            header={tile6.header}
                            button={{
                                title: tile6.buttonText,
                                href: tile6.link,
                            }}
                        />
                        <Fact
                            bigHeader
                            header={speakersNumber}
                            text={tile7.text}
                        />
                        <Fact
                            header={tile8.header}
                            backgroundType="orange"
                            button={{
                                title: tile8.buttonText,
                                onClick: () => scrollTo("contact"),
                            }}
                        />
                        <Fact
                            bigHeader
                            header={
                                events.all.length +
                                conferencesNumber +
                                eventsAttended
                            }
                            text={tile9.text}
                        />
                        <Fact
                            bigHeader
                            header={insightsLength}
                            text={tile10.text}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

InfoGrid.propTypes = {
    props: PropTypes.exact({
        subheader: PropTypes.string.isRequired,
        header: PropTypes.string.isRequired,
        removeMargin: PropTypes.bool,
        facts: PropTypes.array.isRequired,
    }),
}

export default InfoGrid
