// extracted by mini-css-extract-plugin
export var Organizer = "Organizer-module--Organizer--DGcRj";
export var Organizer__Photo = "Organizer-module--Organizer__Photo--WqNgI";
export var Organizer__Data = "Organizer-module--Organizer__Data--3FF9m";
export var Organizer__Position = "Organizer-module--Organizer__Position--2esVT";
export var Organizer__Title = "Organizer-module--Organizer__Title--3oJp6";
export var Organizer__TitleWrapper = "Organizer-module--Organizer__TitleWrapper--2nEXc";
export var Organizer__Content = "Organizer-module--Organizer__Content--lPuK4";
export var Organizer__Contact = "Organizer-module--Organizer__Contact--BvneK";
export var Organizer__Calendar = "Organizer-module--Organizer__Calendar--2Senj";
export var Organizer__Container = "Organizer-module--Organizer__Container--2IpP_";