import React from "react"
import * as styles from "./styles"
import classnames from "classnames"
import PropTypes from "prop-types"
import Icon from "../../../ui/icons/Icon"
import Logo from "../../../../images/spotify-logo.svg"

const Box = ({ text, link, seasonView }) => {
    return (
        <a
            target="_blank"
            href={link}
            rel="noopener noreferrer nofollow"
            className={classnames(styles.Box, {
                [styles.Box__WithSeasons]: seasonView,
            })}
        >
            <div dangerouslySetInnerHTML={{ __html: text }} />
            <div className={styles.Box__Logo}>
                <img src={Logo} />
                <Icon icon="arrow2" />
            </div>
        </a>
    )
}

Box.propTypes = {
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
}

export default Box
