import React from "react"
import * as styles from "./styles"
import PropTypes from "prop-types"
import OutlineButton from "../../../ui/outline-button/OutlineButton"
import classnames from "classnames"
import Sequence from "./Sequence"
import usePhrasesContext from "../../../../hooks/usePhrasesContext"

const Pagination = ({activePage, numberOfPages, setActivePage, settings}) => {
    const {nextText, previousText} = usePhrasesContext()
    const setNextPage = () => {
        setActivePage(prev => {
            if (prev !== numberOfPages) {
                return prev + 1
            }
            return prev
        })
    }

    const setPrevPage = () => {
        setActivePage(prev => {
            if (prev !== 1) {
                return prev - 1
            }
            return prev
        })
    }

    return (<>
            {numberOfPages > 1 &&
                <div className={styles.Pagination}>
                    {activePage !== 1 ? (
                        <OutlineButton
                            onClick={() => setPrevPage()}
                            iconLeft
                            text={previousText}
                        />
                    ) : (
                        <div/>
                    )}

                    <div>
                        <Sequence settings={settings.prev}/>
                        <span
                            className={classnames(styles.Pagination__ActivePage, {
                                [styles.Pagination__ActivePageLast]:
                                activePage === numberOfPages,
                            })}
                            dangerouslySetInnerHTML={{__html: activePage}}
                        />
                        <Sequence settings={settings.next}/>
                    </div>
                    {activePage !== numberOfPages ? (
                        <OutlineButton onClick={() => setNextPage()} text={nextText}/>
                    ) : (
                        <div/>
                    )}
                </div>
            }
        </>
    )
}

Pagination.propTypes = {
    activePage: PropTypes.number.isRequired,
    numberOfPages: PropTypes.number.isRequired,
    setActivePage: PropTypes.func.isRequired,
    settings: PropTypes.object.isRequired,
}

export default Pagination
