import React from "react"
import Icon from "../../../ui/icons/Icon"
import * as styles from "./styles"

const Elements = () => {
    return (
        <>
            <div className={styles.Crosses} />
            <div className={styles.Plus1}>
                <Icon icon="plus" />
            </div>
            <div className={styles.Plus2}>
                <Icon icon="plus" />
            </div>
            <div className={styles.Square1} />
            <div className={styles.Square2} />
            <div className={styles.Square3} />
        </>
    )
}

export default Elements
