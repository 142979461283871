import React from "react"
import Icon from "../../../ui/icons/Icon"
import * as styles from "./styles"
import PropTypes from "prop-types";

const Tile = ({icon, text}) => {
    return (
        <div className={`small-12 medium-6 large-4 ${styles.Tile}`}>
            <Icon size={48} icon={icon} />
            <div dangerouslySetInnerHTML={{__html: text}}/>
        </div>
    )
}

Tile.propTypes = {
    props: PropTypes.exact({
        icon: PropTypes.string,
        text: PropTypes.string,
    }).isRequired,
}
export default Tile
