import React from "react"
import propTypes from "prop-types"

import * as styles from "./styles"
import HubspotForm from "../../functional/hubspot/HubspotForm"

const Newsletter = ({
    props: {
        header,
        subheader,
        text,
        hubspotFormId,
        hubspotFormSlug,
        personalDataInfo,
    },
}) => {
    return (
        <section id="newsletter" className={styles.Newsletter}>
            <div className={styles.Newsletter__Content}>
                <div className={`l-Inner row ${styles.Newsletter__Row}`}>
                    <div className="columns small-12 large-5 xlarge-3">
                        {subheader && (
                            <h4
                                dangerouslySetInnerHTML={{ __html: subheader }}
                            />
                        )}
                        <h1 dangerouslySetInnerHTML={{ __html: header }} />
                        <p dangerouslySetInnerHTML={{ __html: text }} />
                    </div>
                    <div
                        className={`columns small-12 large-6 large-offset-1 xlarge-offset-3 ${styles.Newsletter__Form}`}
                    >
                        <HubspotForm
                            formId={hubspotFormId}
                            slug={hubspotFormSlug}
                            personalDataInfo={personalDataInfo}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

Newsletter.propTypes = {
    header: propTypes.string.isRequired,
    subheader: propTypes.string,
    text: propTypes.string,
    hubspotFormId: propTypes.string,
    hubspotFormSlug: propTypes.string,
}

export default Newsletter
