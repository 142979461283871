import React from "react"
import PropTypes from "prop-types"
import * as styles from "./styles"
import useMediaContext from "../../../../../hooks/useMediaContext"
import Label from "../../../../ui/label/Label"
import { Link } from "gatsby"
import routes from "../../../../../config/routes"
import Icon from "../../../../ui/icons/Icon"

const Podcast = ({ id, slug, title, playText, type }) => {
    const { setMedia } = useMediaContext()
    return (
        <div className={styles.Podcast}>
            <div className={styles.Podcast__Button}>
                <button
                    onClick={() =>
                        setMedia({
                            id,
                            active: true,
                            type: "audio",
                        })
                    }
                >
                    <Icon icon="microphone" size={24} />
                </button>
                <span
                    className={styles.Podcast__ButtonDescription}
                    dangerouslySetInnerHTML={{ __html: playText }}
                />
            </div>

            <div className={styles.Podcast__Content}>
                <Label type={type} />

                <Link
                    to={`${routes.insights}/${slug}`}
                    dangerouslySetInnerHTML={{ __html: title }}
                />
            </div>
        </div>
    )
}

Podcast.propTypes = {
    id: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    playText: PropTypes.string.isRequired,
}

export default Podcast
