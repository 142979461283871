import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import * as styles from "./styles"
import Organizer from "./organizer/Organizer"
import Button from "../../ui/button/Button"
import { H1 } from "../../ui/headers/Headers"
import {GatsbyImage} from "gatsby-plugin-image";
import ConnectingLine from "../../ui/connecting-line/ConnectingLine"

const WhoAreWe = ({
    props: { buttonLink, buttonText, content, header, organizers, subheader, addImage, image, connectingLine },
}) => {
    return (
        <section className={classnames(styles.WhoAreWe, {
            [styles.WhoAreWeWithImage]: addImage,
        })}>
            <div
                className={classnames(
                    `row l-Inner ${styles.WhoAreWe__Container}`
                )}
            >
                <div className={classnames("xsmall-12",
                    {
                    'large-3 xlarge-4': !addImage,
                    'large-5': addImage,
                    }
                    )}>
                    <div className={styles.WhoAreWe__Content}>
                        <h4 dangerouslySetInnerHTML={{ __html: subheader }} />
                        <H1 content={header} />
                        <div dangerouslySetInnerHTML={{ __html: content }} />
                        <div className={styles.WhoAreWe__ContentButton}>
                            <Button
                                dataAtts={{
                                    click: "section-whoweare-btn-moreabout",
                                    name: "More about Fuutre Processing",
                                }}
                                iconRight="arrow2"
                                href={buttonLink}
                            >
                                {buttonText}
                            </Button>
                        </div>
                    </div>
                </div>
                <div
                    className={classnames(`xsmall-12  ${styles.WhoAreWe__Organizers}`, {
                        'large-9 xlarge-8': !addImage,
                        'large-7': addImage,
                    })}
                >
                    {organizers.map(organizer => {
                        const {
                            title,
                            content,
                            id,
                            acf: {
                                position,
                                email,
                                linkedinlink,
                                calendarLink,
                                photo: {
                                    localFile: {
                                        childImageSharp: {
                                            gatsbyImageData: photo,
                                        },
                                    },
                                },
                            },
                        } = organizer.organizer
                        return (
                            <Organizer
                                title={title}
                                content={content}
                                key={id}
                                photo={photo}
                                email={email}
                                calendarLink={calendarLink}
                                linkedinlink={linkedinlink}
                                position={position}
                            />
                        )
                    })}
                    {(addImage && image) && (
                        <GatsbyImage
                            className={styles.WhoAreWe__Image}
                            image={
                                image.localFile.childImageSharp.gatsbyImageData
                            }
                            alt=""
                        />
                    )}
                </div>
            </div>
            {connectingLine && <ConnectingLine bottom/>}
        </section>
    )
}

WhoAreWe.propTypes = {
    props: PropTypes.exact({
        buttonLink: PropTypes.string,
        buttonText: PropTypes.string,
        content: PropTypes.string,
        header: PropTypes.string,
        organizers: PropTypes.array,
        subheader: PropTypes.string,
    }).isRequired,
}

export default WhoAreWe
