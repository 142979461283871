import React from "react"
import PropTypes from "prop-types"
import * as styles from "./styles"
import usePostsContext from "../../../../hooks/usePostsContext"
import { Link } from "gatsby"
import routes from "../../../../config/routes"
import Icon from "../../../ui/icons/Icon"
import Podcast from "./podcast/Podcast"

const Boxes = ({
    header,
    subheader,
    counterBoxSubheader,
    counterBoxHeader,
    playPodcastText,
    excludedId
}) => {
    const { insights, events } = usePostsContext()
    const eventsWithRecordings = events.all.filter(event => event.acf.recordings === true);


    const recentPodcasts = insights.all
        .filter(insight => (insight.id !== excludedId && (insight.acf.type === "podcast" || insight.acf.type === "interview" || insight.acf.type === "webinar-podcast")))
        .sort((a, b) => {
            return b.acf.date > a.acf.date
                ? 1
                : b.acf.date < a.acf.date
                ? -1
                : 0
        })
        .slice(0, 2)
    return (
        <div className={styles.Boxes}>
            <div className={styles.Boxes__Intro}>
                <h4 dangerouslySetInnerHTML={{ __html: subheader }} />
                <h2 dangerouslySetInnerHTML={{ __html: header }} />
            </div>

            <div className={`${styles.Boxes__Wrapper} row`}>
                {recentPodcasts?.map(({ title, slug, acf: { podcastid, type } }) => (
                    <div className="small-12 large-5">
                        <Podcast
                            id={podcastid}
                            title={title}
                            slug={slug}
                            playText={playPodcastText}
                            type={type}
                        />
                    </div>
                ))}

                <div className="small-12 large-2">
                    <Link
                        className={styles.Boxes__Counter}
                        to={routes.insights}
                    >
                        <div>
                            <h3
                                className={styles.Boxes__CounterTitle}
                                dangerouslySetInnerHTML={{
                                    __html: counterBoxHeader,
                                }}
                            />
                            <div className={styles.Boxes__CounterText}>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: counterBoxSubheader,
                                    }}
                                />
                                <Icon icon="arrow2" />
                            </div>
                        </div>

                        <span
                            className={styles.Boxes__Number}
                            dangerouslySetInnerHTML={{
                                __html: insights.all.length + eventsWithRecordings.length,
                            }}
                        />
                    </Link>
                </div>
            </div>
        </div>
    )
}

Boxes.propTypes = {
    header: PropTypes.string.isRequired,
    subheader: PropTypes.string.isRequired,
    counterBoxSubheader: PropTypes.string.isRequired,
    counterBoxHeader: PropTypes.string.isRequired,
    playPodcastText: PropTypes.string.isRequired,
    excludedId: PropTypes.string.isRequired
}

export default Boxes
