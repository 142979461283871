import { useState, useMemo, useEffect, useRef } from "react"
import usePostsContext from "../../../hooks/usePostsContext"

const usePagination = ({
    onlyEvents,
    onlyDataResources,
    insightsFilterTag,
    postsPerPage = 6
}) => {
    const { insights, events } = usePostsContext()
    const [activePage, setActivePage] = useState(1)
    const initialRender = useRef(true)

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false
            return
        }
        const section = document.querySelector("#resources-list")
        if (!section) return
        section.scrollIntoView({ behavior: "smooth", block: "start" })
    }, [activePage])

    const getAllResources = () => {
        const eventsData = onlyDataResources
            ? events.all.filter(event => event.acf.recordings === true)
            : events.all
        const resources = onlyEvents
            ? [...events.all]
            : insightsFilterTag
            ? [
                  ...insights.all.filter(insight => {
                      let tags = []
                      if (insight.tags.nodes.length > 0) {
                          insight.tags.nodes.forEach(tag => {
                              tags.push(tag.name)
                          })
                      }

                      return tags.includes(insightsFilterTag)
                  }),
              ]
            : [...insights.all, ...eventsData]

        return resources.sort((a, b) => {
            const dateA = new Date(a.acf.date)
            const dateB = new Date(b.acf.date)
            return dateB > dateA ? 1 : dateB < dateA ? -1 : 0
        })
    }

    const allResources = useMemo(() => getAllResources(), [])

    const generatePaginatedResources = () => {
        return allResources.reduce((paginatedPosts, post, index) => {
            const pageIndex = Math.floor(index / postsPerPage)

            if (!paginatedPosts[pageIndex]) {
                paginatedPosts[pageIndex] = []
            }

            paginatedPosts[pageIndex].push(post)

            return paginatedPosts
        }, [])
    }

    const paginatedResources = useMemo(() => generatePaginatedResources(), [])
    const numberOfPages = paginatedResources.length

    const settings = {
        prev: {
            firstElement: {
                visible: activePage > 2,
                callback: () => setActivePage(1),
                text: 1,
            },
            showIndicator: activePage > 3,
            lastElement: {
                visible: activePage !== 1,
                callback: () => setActivePage(activePage - 1),
                text: activePage - 1,
            },
        },
        next: {
            firstElement: {
                visible: activePage !== numberOfPages,
                callback: () => setActivePage(activePage + 1),
                text: activePage + 1,
            },
            showIndicator: activePage < numberOfPages - 2,
            lastElement: {
                visible: activePage + 1 < numberOfPages,
                callback: () => setActivePage(numberOfPages),
                text: numberOfPages,
            },
        },
    }

    return {
        allResources,
        paginatedResources,
        activePage,
        setActivePage,
        numberOfPages,
        settings,
    }
}
export default usePagination