import React from "react"
import classnames from "classnames"
import * as styles from "./styles"

const FactWrapper = ({ link, className, children }) =>
    link ? (
        <a
            href={link}
            target="_blank"
            rel="noreferrer nofollow"
            className={classnames(className, styles.Fact__LinkTile)}
        >
            {children}
        </a>
    ) : (
        <div className={className}>{children}</div>
    )

export default FactWrapper
