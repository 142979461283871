import React, {useState, useEffect} from "react"
import PropTypes from "prop-types"
import * as styles from "./styles"
import {H1} from "../../ui/headers/Headers"
import LinearPattern from "../../ui/linear-pattern/LinearPattern"
import useQuery from "./useQuery"
import Slider from "./slider/Slider"

const Conferences = ({props: {grayBackground, title, subtitle, intro, emailSubject, filterTag}}) => {
    const conferencesData = useQuery()
    const [conferencesToShow, setConferencesToShow] = useState(
        conferencesData.slice(0, 4)    )

    const filterConferences = (conferencesData) => {
        if (!filterTag) {
            return conferencesData
        }

        return conferencesData.filter(conference => {
            const conferenceTags = conference.node.tags.nodes

            if (conferenceTags.length === 0) {
                return false
            }

            return conferenceTags.filter(tag => {
                return tag.name === filterTag 
            }).length > 0
        })
    }

    const getClosestConferences = () => {
        const conferencesCount = 4
        const today = new Date().setHours(0, 0, 0, 0)
        let endIndex = conferencesCount
        const filteredConferences = filterConferences(conferencesData)

        for (let i = 0; i < filteredConferences.length; i++) {
            const conferenceDate = new Date(
                filteredConferences[i].node.acf.startDate
            )

            if (conferenceDate < today) {
                break
            }

            if (i >= conferencesCount) {
                endIndex = i + 1
            }
        }

        const startIndex = endIndex - conferencesCount
        setConferencesToShow(
            filteredConferences.slice(startIndex, endIndex).reverse()
        )
    }

    useEffect(() => {
        getClosestConferences()
    }, [])

    return (
        <section className={`${styles.Conferences} ${grayBackground ? styles.Conferences__Gray : ''}`}>
            <div className="row l-Inner">
                <div className={`small-12 ${styles.Conferences__Intro}`}>
                    <h4 dangerouslySetInnerHTML={{__html: subtitle}}/>
                    <H1 content={title}/>
                    <p dangerouslySetInnerHTML={{__html: intro}}/>
                </div>
            </div>
            <div className={styles.Conferences__Items}>
                <Slider
                    slides={conferencesToShow}
                    emailSubject={emailSubject}
                />

                <div className={styles.Conferences__Pattern}>
                    <LinearPattern/>
                </div>
            </div>
        </section>
    )
}

Conferences.propTypes = {
    grayBackground: PropTypes.bool,
    subtitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    intro: PropTypes.string.isRequired,
    filterTag: PropTypes.string
}

export default Conferences
