import React from "react"
import * as styles from "./styles"
import classnames from "classnames"

const WithMargin = Component => {
    return function WithMarginComponent({ ...props }) {
        return (
            <div
                className={classnames(styles.Wrapper, {
                    [styles.WrapperNoMargin]: props.props.removeMargin,
                })}
            >
                <Component {...props} />
            </div>
        )
    }
}

export default WithMargin
