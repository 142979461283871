import React from "react"
import * as styles from "./styles"
import classnames from "classnames"
import PropTypes from "prop-types"
import LinearPattern from "../../ui/linear-pattern/LinearPattern"
import Img from "gatsby-image"
import Breadcrumbs from "../../common/breadcrumbs/Breadcrumbs"
import {H1} from "../../ui/headers/Headers"
import {GatsbyImage} from "gatsby-plugin-image"

const BannerSimple = ({props}) => {
    const {
        subtitle,
        title,
        intro,
        image,
        showBreadcrumbs,
        breadcrumbsPageTitle,
        breadcrumbsParentTitle,
        breadcrumbsParentPath
    } = props

    return (
        <section className={classnames("t-Dark", styles.BannerSimple)}>
            <div
                className={classnames(
                    "row l-Inner",
                    styles.BannerSimple__Container,
                    {[styles.BannerSimple__ContainerWithBreadcrumbs]: showBreadcrumbs},
                )}
            >
                <div className={"small-12"}>
                    {showBreadcrumbs && (
                        <Breadcrumbs
                            page={breadcrumbsParentTitle || "Events"}
                            title={breadcrumbsPageTitle || title}
                            link={breadcrumbsParentPath || "/events"}
                        />
                    )}
                </div>
                <div
                    className={classnames(
                        "small-12 medium-10 large-4",
                        styles.BannerSimple__Text
                    )}
                >
                    <h4 dangerouslySetInnerHTML={{__html: subtitle}}/>
                    <H1 content={title}/>
                    <div dangerouslySetInnerHTML={{__html: intro}}/>
                </div>
                <div className="small-10 large-6 large-offset-1">
                    {image && (
                        <GatsbyImage
                            className={styles.BannerSimple__Image}
                            image={
                                image.localFile.childImageSharp.gatsbyImageData
                            }
                            alt=""
                        />
                    )}
                </div>
            </div>
            <LinearPattern/>
        </section>
    )
}

BannerSimple.propTypes = {
    props: PropTypes.exact({
        subtitle: PropTypes.string,
        title: PropTypes.string,
        intro: PropTypes.string,
        image: PropTypes.object,
    }).isRequired,
}

export default BannerSimple
