import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import * as styles from "./styles"

const Tabs = ({ seasonsNumber, activeTab, setActiveTab, episodesNumber }) => {
    const tabs = Array.from({ length: seasonsNumber }, (_, i) => i + 1)
   
    return (
        <div className={classnames(styles.Tabs, {
            [styles.Tabs__Empty]: episodesNumber === 0,
        })}>
            {tabs.map((tab, index) => (
                <button
                    key={index} 
                    className={classnames(styles.Tabs__Button, {[styles.Tabs__ButtonActive]: tab === activeTab })}
                    onClick={() => setActiveTab(tab)}
                    dangerouslySetInnerHTML={{__html: 'Season ' + tab}}
                />
            ))}
        </div>
    )
}

Tabs.propTypes = {
    seasonsNumber: PropTypes.number.isRequired,
    activeTab: PropTypes.number.isRequired,
    setActiveTab: PropTypes.func.isRequired
}

export default Tabs