import React from "react"
import { graphql } from "gatsby"
import SectionParser from "./section-parser/SectionParser"
import Layout from "../components/layout/Layout"
import Seo from "../components/functional/seo/Seo"

const Page = ({ data }) => {
    const {
        wpChildren: { nodes: sections },
        acfMainPage: { title, description },
    } = data.wpPage

    return (
        <Layout>
            <Seo
                ogImage={
                    data.wp.general.acf.generalOgImage.localFile.childImageSharp
                        .gatsbyImageData.images.fallback.src
                }
                lang="en"
                title={title}
                description={description}
            />
            {data && <SectionParser sections={sections} />}
        </Layout>
    )
}

export const query = graphql`
    query($id: String!) {
        wp {
            general {
                acf {
                    generalOgImage {
                        localFile {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                    }
                }
            }
        }
        wpPage(id: { eq: $id }) {
            title
            acfMainPage {
                title
                description
            }
            wpChildren {
                nodes {
                    slug
                    ... on WpPage {
                        ...AwardBanner
                        ...BannerSimple
                        ...BasicBanner
                        ...ChildPage
                        ...Contact
                        ...Cta
                        ...ImageText
                        ...InfoGrid
                        ...MainBanner
                        ...WhoAreWe
                        ...ResourcesList
                        ...Conferences
                        ...ThreeTiles
                        ...Tiles
                        ...Newsletter
                        menuOrder
                        id
                    }
                }
            }
        }
    }
`

export default Page
