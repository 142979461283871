import {
    AwardBanner,
    BannerSimple,
    BasicBanner,
    Conferences,
    Contact,
    Cta,
    ImageText,
    InfoGrid,
    MainBanner,
    Newsletter,
    WhoAreWe,
    Tiles,
    ThreeTiles,
    ResourcesList
} from "../../components/sections"
import WithMargin from "../../components/hoc/WithMargin"

export const config = {
    awardBanner: {
        section: WithMargin(AwardBanner),
        acf: "acfAwardBanner",
    },
    bannerSimple: {
        section: WithMargin(BannerSimple),
        acf: "acfBannerSimple",
    },
    cta: {
        section: WithMargin(Cta),
        acf: "acfCta",
    },
    imageText: {
        section: WithMargin(ImageText),
        acf: "acfImageText",
    },
    infoGrid: {
        section: WithMargin(InfoGrid),
        acf: "acfInfoGrid",
    },
    mainBanner: {
        section: WithMargin(MainBanner),
        acf: "acfMainBanner",
    },
    contact: {
        section: WithMargin(Contact),
        acf: "acfContact",
    },
    newsletter: {
        section: WithMargin(Newsletter),
        acf: "acfNewsletter",
    },
    threeTiles: {
        section: WithMargin(ThreeTiles),
        acf: "acfThreeTiles",
    },
    basicBanner: {
        section: WithMargin(BasicBanner),
        acf: "acfBasicBanner",
    },
    tiles: {
        section: WithMargin(Tiles),
        acf: "acfTiles",
    },
    conferences: {
        section: WithMargin(Conferences),
        acf: "acfConferences",
    },
    resourcesList: {
        section: WithMargin(ResourcesList),
        acf: "acfResourcesList",
    },
    whoAreWe: {
        section: WithMargin(WhoAreWe),
        acf: "acfWhoAreWe",
    },
}
