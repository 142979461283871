import React from "react"
import PropTypes from "prop-types"

import * as styles from "./styles"

import { H1 } from "../../ui/headers/Headers"
import Boxes from "./boxes/Boxes"
import HotInsight from "./hot-insight/HotInsight"
import WithEntrance from "../../hoc/WithEntrance"

const MainBanner = ({
    props: {
        header,
        boxesHeader,
        boxesSubheader,
        hotInsight,
        counterBoxSubheader,
        counterBoxHeader,
        playPodcastText,
    },
}) => {
    const {
        id: hotInsightId,
        title: hotInsightTitle,
        slug: hotInsightSlug,
        acf: { videoid, image: hotInsightImage, type : hotInsightType },
    } = hotInsight
    return (
        <section className={`${styles.MainBanner} t-Dark`}>
            <div className={`l-Inner row ${styles.MainBanner__Wrapper}`}>
                <div className="small-12 large-4 xlarge-5">
                    <WithEntrance>
                        <H1 className="h1--Big" content={header} />
                        <div className={styles.MainBanner__Intro}>
                            <h4
                                dangerouslySetInnerHTML={{
                                    __html: boxesSubheader,
                                }}
                            />
                            <h2
                                dangerouslySetInnerHTML={{
                                    __html: boxesHeader,
                                }}
                            />
                        </div>
                    </WithEntrance>
                </div>
                <div
                    className={`${styles.MainBanner__HotInsight} small-12 large-8 xlarge-7`}
                >
                    <HotInsight
                        slug={hotInsightSlug}
                        title={hotInsightTitle}
                        image={hotInsightImage}
                        webinarId={videoid}
                        type={hotInsightType}
                    />
                </div>
                <div className="small-12">
                    <Boxes
                        counterBoxSubheader={counterBoxSubheader}
                        counterBoxHeader={counterBoxHeader}
                        playPodcastText={playPodcastText}
                        header={boxesHeader}
                        subheader={boxesSubheader}
                        excludedId={hotInsightId}
                    />
                </div>
            </div>
        </section>
    )
}

MainBanner.propTypes = {
    props: PropTypes.exact({
        header: PropTypes.object,
        boxesHeader: PropTypes.string,
        boxesSubheader: PropTypes.string,
        hotInsight: PropTypes.object,
        counterBoxSubheader: PropTypes.string,
        counterBoxHeader: PropTypes.string,
        playPodcastText: PropTypes.string,
    }).isRequired,
}

export default MainBanner
