import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import dataAttributes from "../../../../utils/dataAttributes"
import LinkedinIcon from "../../../../images/linkedin.svg"
import * as styles from "./styles"
import Icon from "../../../ui/icons/Icon"

const Representative = ({
    title,
    email,
    linkedinlink,
    emailsubject,
    position,
    photo,
}) => {
    return (
        <div className={styles.Representative}>
            <div className={styles.Representative__Photo}>
                <GatsbyImage
                    image={photo.localFile.childImageSharp.gatsbyImageData}
                    className={styles.Representative__Image}
                    alt={title}
                />
                <a
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    href={linkedinlink}
                    {...dataAttributes(
                        "conferences-people-box-linkedin",
                        "Linkedin profile"
                    )}
                >
                    <img src={LinkedinIcon} alt="linkedin" />
                </a>
            </div>
            <div>
                <p
                    className={styles.Representative__Name}
                    dangerouslySetInnerHTML={{ __html: title }}
                />
                <p
                    className={styles.Representative__Position}
                    dangerouslySetInnerHTML={{ __html: position }}
                />
                <a
                    className={styles.Representative__Link}
                    {...dataAttributes(
                        "conferences-people-box-email",
                        "Contact me"
                    )}
                    href={`mailto: ${email}?subject=${emailsubject}`}
                >
                    Contact me
                    <Icon icon="arrow2" />
                </a>
            </div>
        </div>
    )
}

Representative.propTypes = {
    title: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    linkedinlink: PropTypes.string.isRequired,
    emailsubject: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    photo: PropTypes.object.isRequired,
}

export default Representative
