import React, { useEffect, useState } from "react"
import * as styles from "./styles"
import Intro from "./intro/Intro"
import Box from "./box/Box"
import List from "./list/List"
import Pagination from "./pagination/Pagination"
import usePagination from "./usePagination"
import ConnectingLine from "../../ui/connecting-line/ConnectingLine"
import useEventPhrases from "../../../hooks/useEventPhrases"
import Form from "../form/Form"
import Icon from "../../ui/icons/Icon"
import Tabs from "../../ui/tabs/Tabs"

const ResourcesList = ({
    props: {
        header,
        subheader,
        intro,
        spotifyBoxText,
        spotifyLink,
        onlyEvents,
        onlyDataResources,
        lightIntro,
        connectingLine,
        insightsFilterTag,
        listView = false,
        hasSeasons = false,
        seasonsNumber
    },
}) => {
    const postsPerPage = listView ? 10 : 6
    const defaultPhrases = useEventPhrases()
    const [formData, setFormData] = useState(false)
    const [activeTab, setActiveTab] = useState(1)
    const [episodesNumber, setEpisodesNumber] = useState(0)

    const {
        allResources,
        paginatedResources,
        activePage,
        numberOfPages,
        setActivePage,
        settings
    } = usePagination({
        onlyEvents: onlyEvents,
        onlyDataResources,
        insightsFilterTag: insightsFilterTag,
        postsPerPage: postsPerPage,
    })

    useEffect(() => {
        const filteredResources = allResources.filter(item => item.acf && item.acf.seasonNumber === activeTab)
        const filteredResourcesLength = filteredResources.length
        setEpisodesNumber(filteredResourcesLength)
    }, [activeTab])

    return (
        <div id="resources-list">
            {connectingLine && <ConnectingLine />}
            <div className="l-Inner row">
                <div className="small-12 medium-8 large-5">
                    <Intro
                        subheader={subheader}
                        header={header}
                        text={intro}
                        lightTheme={lightIntro}
                    />
                </div>
            </div>

            <div className={`l-Inner row ${styles.ResourcesList__List}`}>
                {spotifyBoxText && (
                    <div
                        className={`small-12 ${styles.ResourcesList__BoxContainer}`}
                    >
                        <Box 
                            text={spotifyBoxText} 
                            link={spotifyLink}
                            seasonView={hasSeasons} 
                        />
                    </div>
                )}
                {formData ? (
                    <div className={styles.ResourcesList__Form}>
                        <div
                            className={styles.ResourcesList__Close}
                            onClick={() => setFormData(false)}
                        >
                            <Icon icon="times" />
                            close
                        </div>
                        <Form
                            props={{
                                title: formData.title,
                                formData,
                                defaultHeader:
                                    defaultPhrases.en.materialsForm.header,
                                defaultSubheader:
                                    defaultPhrases.en.materialsForm.subheader,
                                defaultHubspotFormId:
                                    defaultPhrases.en.materialsForm
                                        .hubspotFormId,
                                defaultHubspotFormSlug:
                                    defaultPhrases.en.materialsForm
                                        .hubspotFormSlug,
                                defaultThankYouHeader:
                                    defaultPhrases.en.materialsForm
                                        .thankYouHeader,
                                defaultThankYouText:
                                    defaultPhrases.en.materialsForm
                                        .thankYouText,
                            }}
                        />
                    </div>
                ) : (
                    <>
                        {hasSeasons && 
                            <Tabs 
                                seasonsNumber={seasonsNumber} 
                                activeTab={activeTab} 
                                setActiveTab={setActiveTab}
                                episodesNumber={episodesNumber}
                            />
                        }
                        <List
                            setFormData={setFormData}
                            resources={hasSeasons ? allResources : paginatedResources[activePage - 1]}
                            onlyEvents={onlyEvents}
                            listView={listView}
                            seasonView={hasSeasons}
                            activeSeason={activeTab}
                        />
                    </>
                )}
                {(!formData && !hasSeasons) && (
                    <div className="small-12">
                        <Pagination
                            setActivePage={setActivePage}
                            activePage={activePage}
                            numberOfPages={numberOfPages}
                            settings={settings}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default ResourcesList
