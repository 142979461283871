import { useStaticQuery, graphql } from "gatsby"

const useQuery = () => {
    const data = useStaticQuery(
        graphql`
            query {
                allWpSpeaker {
                    totalCount
                }
                allWpConference {
                    totalCount
                }
            }
        `
    )

    const { allWpSpeaker, allWpConference } = data

    return {
        speakersNumber: allWpSpeaker.totalCount,
        conferencesNumber: allWpConference.totalCount,
    }
}
export default useQuery
