import React from "react"
import propTypes from "prop-types"

import * as styles from "./styles"

import LinearPattern from "../../ui/linear-pattern/LinearPattern"
import { H1 } from "../../ui/headers/Headers"
import usePostsContext from "../../../hooks/usePostsContext"
import Elements from "./elements/Elements"
import WithEntrance from "../../hoc/WithEntrance"

const BasicBanner = ({ props: { overwriteNumber, number, header, subheader, orangeText } }) => {
    const { insights, events } = usePostsContext()
    const eventsWithRecordings = events.all.filter(event => event.acf.recordings === true);

    return (
        <div className={styles.BasicBanner}>
            <div className="l-Inner">
                <WithEntrance>
                    <div className={styles.BasicBanner__Texts}>
                        <H1
                            className="h1--Big"
                            content={`<strong>+${(overwriteNumber && number) ? number :
                                Math.floor((insights.all.length + eventsWithRecordings.length) / 10) * 10
                            } ${orangeText}</strong> ${header}`}
                        />
                        <h2
                            className="h2--Big"
                            dangerouslySetInnerHTML={{ __html: subheader }}
                        />
                    </div>
                </WithEntrance>
            </div>
            <Elements />

            <div className={styles.BasicBanner__BottomPattern}>
                <LinearPattern />
            </div>
        </div>
    )
}

BasicBanner.propTypes = {
    props: propTypes.exact({
        overwriteNumber: propTypes.bool,
        number: propTypes.number,
        header: propTypes.string,
        subheader: propTypes.string,
    }).isRequired,
}

export default BasicBanner
