import React from "react"
import PropTypes from "prop-types"
import InsightTile from "../../../common/post/insight-tile/InsightTile"
import EventTile from "../../../common/post/event-tile/EventTile"
import * as styles from "./styles"
import classnames from "classnames";

const List = ({resources, setFormData, onlyEvents, listView, seasonView, activeSeason}) => {
    const isInsight = type => {
        return (
            type === "podcast" ||
            type === "webinar" ||
            type === "interview" ||
            type === "webinar-podcast"
        )
    }
    return (
        <>
            {resources?.map(resource => {
                const {
                    title,
                    slug,
                    content: excerpt,
                    acf: {
                        image: {
                            localFile: {
                                childImageSharp: {gatsbyImageData},
                            },
                        },
                        imagethumbnail: {
                            localFile: {
                                childImageSharp: {gatsbyImageData : gatsbyImageThumbnailData},
                            },
                        },
                        date,
                        type,
                        podcastid,
                        videoid,
                        place,
                        recordings,
                        materialsForm,
                        seasonNumber
                    },
                } = resource
                
                return (
                    <div className={classnames("small-12", {
                        "large-4": !listView,
                        [styles.ListListView]: listView,
                        [styles.List__Hidden]: seasonView && (activeSeason !== seasonNumber),
                    })}>
                        {isInsight(type) ? (
                            <InsightTile
                                image={gatsbyImageData}
                                thumbnailImage={gatsbyImageThumbnailData}
                                date={date}
                                title={title}
                                type={type}
                                slug={slug}
                                mediaId={videoid ?? podcastid}
                                listView={listView}
                            />
                        ) : (
                            <EventTile
                                image={listView ? gatsbyImageThumbnailData : gatsbyImageData}
                                title={title}
                                showLock={recordings && !onlyEvents}
                                date={date}
                                type={type}
                                slug={slug}
                                place={place}
                                materialsForm={materialsForm}
                                setFormData={setFormData}
                            />
                        )}
                    </div>
                )
            })}
        </>
    )
}

List.propTypes = {
    resources: PropTypes.array.isRequired,
    setFormData: PropTypes.func.isRequired,
    onlyEvents: PropTypes.bool,
    listView: PropTypes.bool,
}

export default List
