import React from "react"
import PropTypes from "prop-types"
import * as styles from "./styles"
import Label from "../../../ui/label/Label"
import { GatsbyImage } from "gatsby-plugin-image"
import Button from "../../../ui/button/Button"
import Icon from "../../../ui/icons/Icon"
import useMediaContext from "../../../../hooks/useMediaContext"
import { Link } from "gatsby"
import routes from "../../../../config/routes"

const HotInsight = ({ title, image, slug, webinarId, type }) => {
    const { setMedia } = useMediaContext()
    return (
        <div className={styles.HotInsight}>
            <GatsbyImage
                image={image.localFile.childImageSharp.gatsbyImageData}
                alt={image.altText !== "" ? image.altText : title}
            />
            <div className={styles.HotInsight__Content}>
                <Label type={type} />
                <div className={styles.HotInsight__Button}>
                    <Button
                        onClick={() =>
                            setMedia({
                                id: webinarId,
                                active: true,
                                type: "video",
                            })
                        }
                        play
                    >
                        <Icon icon="full-play" />
                    </Button>
                </div>

                <div className={styles.HotInsight__TitleContainer}>
                    <span
                        className={styles.HotInsight__Hot}
                        dangerouslySetInnerHTML={{ __html: "hot" }}
                    />
                    <Link
                        className={styles.HotInsight__Title}
                        dangerouslySetInnerHTML={{ __html: title }}
                        to={`${routes.insights}/${slug}`}
                    />
                </div>
            </div>
        </div>
    )
}

HotInsight.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    slug: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    webinarId: PropTypes.string.isRequired,
}

export default HotInsight
