import React from "react"
import ReactSlider from "react-slick";
import Conference from "../conference/Conference"
import PropTypes from "prop-types"

const Slider = ({slides, emailSubject}) => {
    const sliderSettings = {
        arrows: false,
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        responsive: [
            {
                breakpoint: 1023,
                settings: {
                    dots: true,
                    slidesToShow: 1,
                }
            }
        ]
    }

    return (
        <div className="l-Inner conferences-slider">
            <ReactSlider
                {...sliderSettings}
            >
                {slides?.map(data => {
                    const {
                        title,
                        acf: {
                            startDate,
                            endDate,
                            conferenceLink,
                            place,
                            representatives,
                            logo
                        },
                    } = data.node

                    return (
                        <Conference
                            key={title + startDate}
                            title={title}
                            startDate={startDate}
                            endDate={endDate}
                            conferenceLink={conferenceLink}
                            place={place}
                            representatives={representatives}
                            logo={logo}
                            emailSubject={emailSubject}
                        />
                    )
                })}
            </ReactSlider>
        </div>
    )
}

Slider.propTypes = {
    slides: PropTypes.array.isRequired,
    emailSubject: PropTypes.string.isRequired
}

export default Slider
