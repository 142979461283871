import { useContext } from "react"
import { TranslationContext } from "../context/TranslationContext"

const usePhrasesContext = () => {
    const {
        phrasesNextText,
        phrasesPreviousText,
        phrasesShowLess,
        phrasesShowMore,
    } = useContext(TranslationContext)

    return {
        nextText: phrasesNextText,
        previousText: phrasesPreviousText,
        showLessText: phrasesShowLess,
        showMoreText: phrasesShowMore,
    }
}

export default usePhrasesContext
