import React from "react"
import { AnimatePresence, motion } from "framer-motion"

const WithEntrance = ({ children }) => {
    return (
        <AnimatePresence>
            <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8, ease: "easeInOut" }}
            >
                {children}
            </motion.div>
        </AnimatePresence>
    )
}

export default WithEntrance
