import React from "react"
import * as styles from "./styles"
import PropTypes from "prop-types"
import {GatsbyImage} from "gatsby-plugin-image";
import classnames from "classnames";
import {H1} from "../../ui/headers/Headers";
import Button from "../../ui/button/Button";
const Cta = ({
                 props: {title, image, buttonText, buttonLink},
             }) => {
    return (
        <section className={styles.Cta}>
            <div className="l-Inner row">
                <div className={classnames("small-12", styles.Cta__Container)}>
                    <H1 content={title}/>
                    <Button
                        iconRight={"arrow2"}
                        href={buttonLink}
                        large
                    >
                        {buttonText}
                    </Button>
                    <div className={styles.Cta__Image}>
                        {
                            <GatsbyImage
                                imgStyle={{objectPosition: "center top"}}
                                image={
                                    image.localFile.childImageSharp.gatsbyImageData
                                }
                                alt={image.altText !== "" ? image.altText : title}
                            />
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

Cta.propTypes = {
    props: PropTypes.exact({
        title: PropTypes.string,
        buttonText: PropTypes.string,
        buttonLink: PropTypes.string,
        image: PropTypes.object,
    }).isRequired,
}

export default Cta
