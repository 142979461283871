// extracted by mini-css-extract-plugin
export var Fact = "Fact-module--Fact--36DMp";
export var tDark = "Fact-module--t-Dark--2HFDj";
export var Fact__Paragraph = "Fact-module--Fact__Paragraph--2P-xh";
export var Fact__NoPadding = "Fact-module--Fact__NoPadding--htlkx";
export var Fact__BackgroundImage = "Fact-module--Fact__BackgroundImage--zpO3C";
export var Fact__ShadowBorder = "Fact-module--Fact__ShadowBorder--559OU";
export var Fact__LogoWrapper = "Fact-module--Fact__LogoWrapper--2UqHO";
export var Fact__Logo = "Fact-module--Fact__Logo--3RsN5";
export var Fact__Icon = "Fact-module--Fact__Icon--t3ihc";
export var Fact__Header = "Fact-module--Fact__Header--2R0pj";
export var Fact__HeaderBig = "Fact-module--Fact__Header--Big--LW9BA";
export var Fact__LinkTile = "Fact-module--Fact__LinkTile--7pFH-";
export var FactDark = "Fact-module--Fact--Dark--3tGF4";