import React from "react"
import PropTypes from "prop-types"

import classnames from "classnames"
import { useStaticQuery, graphql } from "gatsby"
import dataAttributes from "../../../../utils/dataAttributes"

import * as styles from "./styles"

import { GatsbyImage } from "gatsby-plugin-image"
import Icon from "../../../ui/icons/Icon"
import OutlineButton from "../../../ui/outline-button/OutlineButton"
import LinkedinIcon from "../../../../images/linkedin.svg"

const Organizer = ({
    title,
    content,
    photo,
    email,
    emailsubject,
    linkedinlink,
    calendarLink,
    position,
}) => {
    const data = useStaticQuery(
        graphql`
            query {
                wp {
                    contact {
                        acf {
                            contactEn {
                                contactEnPersonText
                                contactEnEmailSubject
                                contactEnCalendarText
                            }
                        }
                    }
                }
            }
        `
    )
    return (
        <div className={classnames(styles.Organizer)}>
            <div className={styles.Organizer__Container}>
                <div
                    dangerouslySetInnerHTML={{ __html: content }}
                    className={styles.Organizer__Content}
                />
                <div className={styles.Organizer__Data}>
                    <div className={styles.Organizer__Photo}>
                        <GatsbyImage image={photo} alt="" />
                    </div>
                    <div className={styles.Organizer__TitleWrapper}>
                        <strong
                            dangerouslySetInnerHTML={{ __html: title }}
                            className={styles.Organizer__Title}
                        />
                        <p
                            className={styles.Organizer__Position}
                            dangerouslySetInnerHTML={{ __html: position }}
                        />

                        <div className={styles.Organizer__Contact}>
                            <a
                                {...dataAttributes(
                                    "section-whoweare-people-box-linkedin",
                                    "Linkedin"
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                                href={linkedinlink}
                            >
                                <img src={LinkedinIcon} alt="linkedin" />
                            </a>
                            <OutlineButton
                                external
                                href={`mailto: ${email}?subject=${
                                    emailsubject
                                        ? emailsubject
                                        : data.wp.contact.acf.contactEn
                                              .contactEnEmailSubject
                                }`}
                                text={
                                    data.wp.contact.acf.contactEn
                                        .contactEnPersonText
                                }
                                small
                                {...dataAttributes(
                                    "section-whoweare-people-box-email",
                                    "Contact me"
                                )}
                                dataAtts={{
                                    click: "section-whoweare-people-box-email",
                                    name: "Contact me",
                                }}
                            />
                        </div>
                    </div>
                </div>
                {calendarLink && (
                    <a
                        {...dataAttributes(
                            "section-whoweare-people-box-calendar",
                            "Lets have call"
                        )}
                        href={calendarLink}
                        className={styles.Organizer__Calendar}
                    >
                        <Icon icon="calendar" />
                        {data.wp.contact.acf.contactEn.contactEnCalendarText}
                    </a>
                )}
            </div>
        </div>
    )
}

Organizer.propTypes = {
    props: PropTypes.exact({
        title: PropTypes.string,
        content: PropTypes.string,
        photo: PropTypes.object,
        email: PropTypes.string,
        emailsubject: PropTypes.string,
        linkedinlink: PropTypes.string,
        calendarLink: PropTypes.string,
        positio: PropTypes.string,
    }).isRequired,
}

export default Organizer
