import React from "react"

const Sequence = ({ settings }) => {
    const { firstElement, showIndicator, lastElement } = settings
    return (
        <>
            {firstElement.visible && (
                <span
                    onClick={() => firstElement.callback()}
                    dangerouslySetInnerHTML={{
                        __html: firstElement.text,
                    }}
                />
            )}

            {showIndicator && (
                <span dangerouslySetInnerHTML={{ __html: "..." }} />
            )}

            {lastElement.visible && (
                <span
                    onClick={() => lastElement.callback()}
                    dangerouslySetInnerHTML={{
                        __html: lastElement.text,
                    }}
                />
            )}
        </>
    )
}

export default Sequence
