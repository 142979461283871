import React from "react"
import { H1 } from "../../ui/headers/Headers"
import Button from "../../ui/button/Button"
import Tile from "./tile/Tile"
import * as styles from "./styles"
import scrollTo from "../../../utils/scrollTo"
import PropTypes from "prop-types"

const Tiles = ({
    props: {
        title,
        subtitle,
        intro,
        tiles,
        buttonText,
        scrollElementId,
        sectionId,
    },
}) => {
    return (
        <section id={sectionId}>
            <div className="l-Inner row">
                <div className="small-12 large-6 xlarge-5">
                    <h4 dangerouslySetInnerHTML={{ __html: subtitle }} />
                    <H1 content={title} />
                    <p dangerouslySetInnerHTML={{ __html: intro }} />
                </div>
            </div>
            <div className={styles.Tiles__Content}>
                <div className="l-Inner row">
                    {tiles?.map((tile, index) => {
                        const { icon, text } = tile

                        return (
                            <Tile key={icon + index} text={text} icon={icon} />
                        )
                    })}
                </div>
            </div>
            <div className={styles.Tiles__ButtonContainer}>
                <div className="l-Inner row">
                    <Button
                        iconRight={"arrow2"}
                        large
                        onClick={() => {
                            scrollTo(scrollElementId)
                        }}
                    >
                        {buttonText}
                    </Button>
                </div>
            </div>
        </section>
    )
}

Tiles.propTypes = {
    props: PropTypes.exact({
        subtitle: PropTypes.string,
        title: PropTypes.string,
        intro: PropTypes.string,
        tiles: PropTypes.array,
        buttonText: PropTypes.string,
        scrollElementId: PropTypes.string,
        sectionId: PropTypes.string,
    }).isRequired,
}

export default Tiles
