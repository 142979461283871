import React from "react"
import * as styles from "./styles"
import {H1} from "../../ui/headers/Headers"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"

const AwardBanner = ({
    props: { subtitle, title, text, image },
}) => {
    return (
        <section className={styles.AwardBanner}>
            <div className={`row l-Inner ${styles.AwardBanner__Wrapper}`}>
                <div className={`xsmall-12 medium-8 large-7 large-offset-1 ${styles.AwardBanner__TextWrapper}`}>
                    <h4 dangerouslySetInnerHTML={{ __html: subtitle }}/>
                    <H1 content={title}/>
                    <div dangerouslySetInnerHTML={{ __html: text }}/>
                </div>
                <div className={`xsmall-12 medium-8 large-4 ${styles.AwardBanner__ImageWrapper}`}>
                    <GatsbyImage
                        image={image.localFile.childImageSharp.gatsbyImageData}
                        alt={image.altText}
                    />
                </div>
            </div>
        </section>
    )
}

AwardBanner.propTypes = {
    props: PropTypes.exact({
        subtitle: PropTypes.string,
        title: PropTypes.string,
        text: PropTypes.string,
        image: PropTypes.object,
    }).isRequired,   
}

export default AwardBanner