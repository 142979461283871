import React from "react"
import * as styles from "./styles"
import PropTypes from "prop-types"
import { H1 } from "../../../ui/headers/Headers"
import classnames from "classnames";

const Intro = ({ subheader, header, text, lightTheme }) => {
    return (
        <div className={classnames(styles.Intro, {
            [styles.IntroIsLight]: lightTheme,
        })}>
            <h4 dangerouslySetInnerHTML={{ __html: subheader }} />
            <H1 content={header} />
            {text &&
            <p dangerouslySetInnerHTML={{__html: text}}/>
            }
        </div>
    )
}

Intro.propTypes = {
    subheader: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    text: PropTypes.string,
    lightTheme: PropTypes.bool
}

export default Intro
