import React from "react"
import * as styles from "./styles"
import { GatsbyImage } from "gatsby-plugin-image"
import Representative from "../representative/Representative"
import PropTypes from "prop-types"

const Conference = ({
    title,
    startDate,
    conferenceLink,
    place,
    emailSubject,
    endDate,
    logo,
    representatives,
}) => {
    const formatDate = date => {
        const currentDate = new Date(date)

        const year = currentDate.getFullYear()
        const month = ("0" + (currentDate.getMonth() + 1)).slice(-2)
        const day = ("0" + currentDate.getDate()).slice(-2)
        return `${day}.${month}.${year}`
    }

    return (
        <div className={styles.Conference}>
            <div>
                <a rel="nofollow" href={conferenceLink}>
                    <GatsbyImage
                        image={logo.localFile.childImageSharp.gatsbyImageData}
                        className={styles.Conference__Logo}
                        alt={title}
                    />
                </a>
                <h3>
                    <a
                        rel="nofollow"
                        href={conferenceLink}
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                </h3>
                <div className={styles.Conference__Text}>
                    <p>Meet us on:</p>
                    <p>
                        {formatDate(startDate)}
                        {endDate && ` - ${formatDate(endDate)}`}
                    </p>
                    <p dangerouslySetInnerHTML={{ __html: place }} />
                </div>
            </div>
            <div>
                {representatives?.map(data => {
                    const {
                        title,
                        acf: { email, linkedinlink, position, photo },
                    } = data

                    return (
                        <Representative
                            key={title}
                            title={title}
                            position={position}
                            email={email}
                            emailsubject={emailSubject}
                            linkedinlink={linkedinlink}
                            photo={photo}
                        />
                    )
                })}
            </div>
        </div>
    )
}

Conference.propTypes = {
    title: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string,
    conferenceLink: PropTypes.string.isRequired,
    place: PropTypes.string.isRequired,
    emailSubject: PropTypes.string.isRequired,
    logo: PropTypes.object.isRequired,
    representatives: PropTypes.array.isRequired,
}

export default Conference
