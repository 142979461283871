import React, { createElement } from "react"
import { config } from "./config"

const SectionParser = ({ sections }) => {
    const sortedSections = sections.sort((a, b) => a.menuOrder - b.menuOrder)

    const parsedSections = sortedSections.map(section => {
        const {
            acfChildPage: { template, removeMargin },
        } = section

        if (!config[template]) return false
        return createElement(config[template].section, {
            key: section.id,
            props: {
                ...section[config[template].acf],
                removeMargin,
            },
        })
    })
    return <>{parsedSections}</>
}

export default SectionParser
