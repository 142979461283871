import { useStaticQuery, graphql } from "gatsby"

const useQuery = () => {
    const data = useStaticQuery(
        graphql`
            query {
                allWpConference(limit: 30, sort: {fields: acf___startDate, order: DESC}) {
                    edges {
                        node {
                            title
                            tags {
                                nodes {
                                    name
                                }
                            }
                            acf {
                                startDate
                                place
                                endDate
                                conferenceLink
                                logo {
                                    ...gatsbyFluidImage
                                }
                                representatives {
                                    ... on WpOrganizer {
                                        title
                                        acf {
                                            email
                                            linkedinlink
                                            position
                                            photo {
                                                ...gatsbyFluidImage
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    )

    return data.allWpConference.edges
}
export default useQuery
