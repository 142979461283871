import React from "react"
import * as styles from "./styles"
import FactWrapper from "./FactWrapper"

import PropTypes from "prop-types"
import classnames from "classnames"
import { GatsbyImage } from "gatsby-plugin-image"

import OutlineButton from "../../../ui/outline-button/OutlineButton"
import Icon from "../../../ui/icons/Icon"

const Fact = ({
    header,
    bigHeader,
    text,
    backgroundType,
    backgroundImage,
    button,
    logo,
    link,
    shadow,
}) => {
    return (
        <FactWrapper
            link={link}
            className={classnames(styles.Fact, {
                [styles.Fact__NoPadding]: backgroundType === "image",
                [styles.FactDark]: backgroundType === "dark",
                "t-BrandGradient": backgroundType === "orange",
                [styles.Fact__ShadowBorder]: shadow === true,
            })}
        >
            {backgroundImage && (
                <GatsbyImage
                    image={
                        backgroundImage.localFile.childImageSharp
                            .gatsbyImageData
                    }
                    className={styles.Fact__BackgroundImage}
                    alt={backgroundImage.altText}
                />
            )}
            {header && (
                <h3
                    className={classnames(styles.Fact__Header, {
                        [styles.Fact__HeaderBig]: bigHeader,
                    })}
                    dangerouslySetInnerHTML={{ __html: header }}
                />
            )}
            {text && (
                <p
                    className={styles.Fact__Paragraph}
                    dangerouslySetInnerHTML={{ __html: text }}
                />
            )}
            {button && (
                <OutlineButton
                    text={button.title}
                    iconRight="arrow2"
                    white
                    href={button.href}
                    onClick={button.onClick}
                    dataAtts={{
                        click: "topbanner-btn-seemore",
                        name: "See more",
                    }}
                />
            )}
            {logo && (
                <div className={styles.Fact__LogoWrapper}>
                    <GatsbyImage
                        image={logo.localFile.childImageSharp.gatsbyImageData}
                        alt={logo.altText}
                        className={styles.Fact__Logo}
                    />
                    <Icon icon={"arrow2"} customClass={styles.Fact__Icon} />
                </div>
            )}
        </FactWrapper>
    )
}

Fact.propTypes = {
    header: PropTypes.string,
    bigHeader: PropTypes.bool,
    text: PropTypes.string,
    backgroundType: PropTypes.string.isRequired,
    backgroundImage: PropTypes.object,
    button: PropTypes.object,
    logo: PropTypes.object,
    link: PropTypes.string,
    shadow: PropTypes.bool,
}

export default Fact
