const scrollTo = scrollElementId => {
    if (scrollElementId && parseInt(scrollElementId) !== scrollElementId) {
        // eslint-disable-line eqeqeq
        const anchor = document.querySelector("#" + scrollElementId)
        if (anchor) {
            anchor.scrollIntoView({ behavior: "smooth", block: "start" })
        }
    }
}

export default scrollTo
